<template>

  <div class="text-center" >

    <v-dialog @keydown.esc="dialog = false"  max-width="700" class="ma-0 pa-0 green" overlay-color="black" 
    overlay-opacity="0.7" > 


          <template v-slot:activator="{on}">
            <v-btn icon dark x-small v-on="on" @click="zeige_aufgaben"><v-icon class="purple--text" >mdi-playlist-edit</v-icon>
            
            <span class="purple--text" v-if="anzahl_aufgaben>0" >({{anzahl_aufgaben}}) </span>
            <span class="purple--text" v-if="anzahl_aufgaben_dateien>0" >({{anzahl_aufgaben_dateien}} Datei) </span>
             
              </v-btn>
         
          </template>

            <v-card>
                <v-card-title class=" grey lighten-2  body-1 "> <div style="position:relative; float:left;">{{gruppen_modul_bezeichnung}} </div> 
                  <!-- ({{anzahl_aufgaben}} Aufgaben)  -->
                  <!-- ### {{current_user_id}}  -->
                </v-card-title>
              
            </v-card>
                  
            
            <!-- **********  Einfügen Button ************ -->
            <div style="position:relative; float:right !important;  z-index:999999999999; width:auto; margin: -40px 10px 0px 0px;"> 
              <v-icon  color="green darken-2"  @click="neue_aufgabe_einfuegen(id_sar, id_typ_sar, id_gmv, id_emv, id_zuordnung_final_gmv_sar, id_zuordnung_final_emv_sar)">mdi-pencil-plus</v-icon> 
            </div> 

          
            <v-card class="text-sm-body-2 mt-1 " elevation="2" v-for=" item in aufgabenliste" :key="item" style="width:99.7%;margin:0px;border:5px solid white;">
               
                        <v-text-field
                          label="Arbeitstitel" 
                          v-model="item.arbeitstitel"
                          @change= "erhalte_wert_von_textfeld([index, $event,item.id_aufgabe,'id_aufgabe','arbeitstitel','tbl_aufgaben'])"
                          hide-details="auto"
                          class="pa-5    css-text-rot "
                          style="width:250px !important;"
                        ></v-text-field>



                    <!-- '******************  Aufgabe löschen' (nur wenn Author/user_id  gleich der angemeldeten UserId ist-->
                    <v-icon  v-if="item.id_user == current_user_id " class=" mt-n16   mr-2 float-right"  color="red"  @click="aufgabe_loeschen(item.id_aufgabe)">mdi-pencil-minus </v-icon> 


                        <v-textarea
                          rows="3"
                          row-height="20"
                          solo
                          label="Info"
                          v-model="item.beschreibung"
                          @change= "erhalte_wert_von_textfeld([index, $event,item.id_aufgabe,'id_aufgabe','beschreibung','tbl_aufgaben'])"
                          hide-details="auto"
                          class="pa-5 mt-n7"
                        ></v-textarea>


                        <v-card-text class="pa-2 ma-2" >
                                <v-select  class="mb-0 mr-2 mt-n7 pt-3 black--text "  outlined  dense style="width:120px; "
                                  :items="aufgabe_status_liste"
                                  :options="options"
                                  :value="item.aufgabe_status"
                                  @change= "erhalte_wert_von_textfeld([index, $event,item.id_aufgabe,'id_aufgabe','aufgabe_status','tbl_aufgaben'])"
          
                                  label="Status"> 
                                </v-select>
                            
                              
                              

                          <!--###############################################################   bei checkboxen muss es  input-value anstelle von value heißen !!!!!!!      -->
                                <v-checkbox class=" mt-n12    mr-2 float-right"
                                    color="#669999"
                                          v-model="item.interne_aufgabe"
                                          label="nur intern"
                                          :disabled="item.id_user != current_user_id "
                                          :value="true"
                                        input-value="value"
                                          @change="update_checkbox(item.id_aufgabe,item.interne_aufgabe)"
                                ></v-checkbox>


                              
                              <span v-if="item.aufgabe_bis_datum" >zu erledigen bis: {{formatDate(item.aufgabe_bis_datum.substr(0, 10))}}</span>


                                          
                      </v-card-text>
                                              
                        <div style="display:inline-block; border:0px solid red; margin:-20px 0px 0px 0px;width:100%; height:auto;">

                                <v-btn type="button"  style="position:relative;float:left;  margin:20px 10px 10px 10px ;  padding:10px 5px 10px 5px;"
                                      depressed
                                      elevation="2"
                                      pa-2
                                      outlined
                                      small
                                      @click="dateilisteupload">
                                      <span>Datei hochladen</span>
                                </v-btn>

                                  <!-- <Camera_neu :id_aufgabe =item.id_aufgabe   :id_zuordnung_final_gmv_sar =id_zuordnung_final_gmv_sar  :id_zuordnung_final_emv_sar =id_zuordnung_final_emv_sar   :id_typ_sar =id_typ_sar  :id_sar =id_sar :id_gmv =id_gmv :id_info =0></Camera_neu> -->
                                <Fotogalerie :id_aufgabe =item.id_aufgabe  :id_sar =id_sar :anzahl_dateien=anzahl_dateien style="margin-top:0px;"></Fotogalerie> 
                               
                             
                        </div>    
            </v-card>
      </v-dialog>
  </div>
</template>

<script>


import AufgabenService from '@/services/AufgabenService.js';
import axios from 'axios';
// import Camera_neu from './Camera__neu.vue';
import Fotogalerie from './Fotogalerie.vue';
import serveradresse_settings from '@/settings/serveradresse_settings.js';

export default ({

data() {
        return {
            aufgabenliste: "",
            msg:"",
            arbeitstitel:"",
            beschreibung:"",
            aufgabe_status:"",
            aufgabe_status_liste: ['erledigt', 'offen', 'in Arbeit', 'Fehler'],
            erledigt_datum:"",
            date: (new Date(Date.now() )).toISOString().substr(0, 10),            menu: false,
            aufgabe_bis_datum:"",
            zeige_kalender:false,
            dateFormatted: '',
            images: null,
            imgSrc: '',
            interne_aufgabe: false,
           // user_id :  localStorage.getItem("user_id"),
            fotoliste: '',
            fotolisteanzeigen: false,
            url_foto_anzeigen: serveradresse_settings.config_server_adresse.url_foto_anzeigen
        }
    },

 methods: {

        //'***************** schickt emit an Parent, damit Liste GM neu geladen wird (mit neuer Anzahl Aufgaben))
                closePopup() {
                  this.$emit('emit_closepopup', 'von_aufgabe');
                },


      //'***************************************'  Öffnet neues Fenster, um Dateien uploaden zu können (Upload-Mobil / aspx-Datei)
        dateilisteupload() {  
            window.open(""+this.url_foto_anzeigen+"/Upload_mobil.aspx?id_emv=0&id_gmv="+this.id_gmv+"&id_sar="+this.id_sar+"&id_typ_sar="+
                this.id_typ_sar+"&id_zuordnung_final_emv_sar=0&id_zuordnung_final_gmv_sar="+this.id_zuordnung_final_gmv_sar+"&id_aufgabe="+this.id_aufgabe
                +"&id_lebenslauf=0&id_werftpunkt=0&id_werftpunkt_sar_einheit=0&id_info=0&id_checkpunkt=0&ordner1=Gruppenmodule&ordner2="
                +this.id_gmv+"&ordner3=Aufgabe-SAR-ID-"+this.id_sar+"&folder=Aufgabe-ID-"+this.id_aufgabe+"", 
            "_blank", "directories=0,titlebar=0,toolbar=0,scrollbars=0,resizable=1,toolbar=0,location=0, top=100,left=200,width=510,height=auto")
        },




        //*********************************  wird nicht mehr benutz, Auflösung nicht so gut, etc (Viedeo screenshot)) */
      uploadFile() {
        this.Images = this.$refs.file.files[0];
      },

      submitFile() {
        const formData = new FormData();
        formData.append('file', this.Images);
        const headers = { 'Content-Type': 'multipart/form-data' };
        axios.post('https://httpbin.org/post', formData, { headers }).then((res) => {
          res.data.files; // binary representation of the file
          res.status; // HTTP status
        });
      },



      async aufgabe_loeschen (id_aufgabe) {
        try {
          const credentials = {
                   
                    id_aufgabe: id_aufgabe,
                    user_id: this.user_id
                };
                const response = await AufgabenService.aufgabe_loeschen(credentials);
                this.refresh_aufgaben_popup();
                this.closePopup() ;
                if (response.msg) { //**********  keine gültige Session */
                    this.msg = response.msg;
                }
           }
            catch (error) {
                this.msg = "Fehler" 
          }

      },
      


      // neue_aufgabe_einfuegen([index, $event,item.id_sar, id_zuordnung_final_gmv_sar])
      async neue_aufgabe_einfuegen(id_sar, id_typ_sar, id_gmv, id_emv, id_zuordnung_final_gmv_sar, id_zuordnung_final_emv_sar) {
        try {
          const credentials = {
                   
                    id_sar: id_sar,
                    id_typ_sar:id_typ_sar,
                    id_gmv: id_gmv,
                    id_emv: id_emv,
                    id_zuordnung_final_gmv_sar: id_zuordnung_final_gmv_sar,
                    id_zuordnung_final_emv_sar: id_zuordnung_final_emv_sar,
                    user_id: this.user_id
                };
                const response = await AufgabenService.neue_aufgabe_einfuegen(credentials);
                this.refresh_aufgaben_popup();
                this.closePopup() ;
                if (response.msg) { //**********  keine gültige Session */
                    this.msg = response.msg;
                }
           }
            catch (error) {
                this.msg = "Fehler" //error.response.data.schiffsliste;
          }
      },

          erhalte_wert_von_textfeld(index) {
                this.update_feld(index)
          },

        async  update_feld(index) {
            try {
                const credentials = {
                    wert: index[1],
                    id: index[2],
                    id_bezeichnung: index[3],
                    feld: index[4],
                    tabelle: index[5]
                };
                const response = await AufgabenService.update_text_feld(credentials);

              //'***************** schickt emit an Parent, damit Liste Aufgaben neu geladen wird (mit neuer Anzahl Aufgaben))
                if(this.herkunft == "von_aufgabenliste") {this.$emit('emit_closepopup', 'von_aufgabe_aufgabenliste');}
               
                  this.zeige_gruppenmodule(localStorage.id_sar_ausgewaehlt_global);

                if (response.msg) { //**********  keine gültige Session */
                    this.msg = response.msg;
                }
            }
            catch (error) {
                this.msg = "Fehler" //error.response.data.schiffsliste;
            }
        },


       //**************************************************************https://codepen.io/jkarczm/pen/LYbYmxz?editors=1010 */
        async  update_checkbox(id_interne_aufgabe,value) {
            try {
                const credentials = {
                    wert: value,
                    id: id_interne_aufgabe,
                    id_bezeichnung: 'id_aufgabe',
                    feld: 'interne_aufgabe',
                    tabelle: 'tbl_aufgaben'
                };

                const response = await AufgabenService.update_checkbox_feld(credentials);
                
              //'***************** schickt emit an Parent, damit Liste Aufgaben neu geladen wird (mit neuer Anzahl Aufgaben))
                if(this.herkunft == "von_aufgabenliste") {this.$emit('emit_closepopup', 'von_aufgabe_aufgabenliste');}
//                   this.zeige_gruppenmodule(localStorage.id_sar_ausgewaehlt_global);
// 
                if (response.msg) { //**********  keine gültige Session */
                    this.msg = response.msg;
                }
            }
            catch (error) {
                this.msg = "Fehler" //error.response.data.schiffsliste;
            }
        },


        async refresh_aufgaben_popup() { 
          try {
               const credentials = {
                    id_sar: this.id_sar,
                    id_zuordnung_final_emv_sar: this.id_zuordnung_final_emv_sar,
                    id_zuordnung_final_gmv_sar: this.id_zuordnung_final_gmv_sar,
                    id_aufgabe:  this.id_aufgabe
                };

               const response = await AufgabenService.zeigeaufgabe_per_id_zuordnung_final_gmv_sar(credentials);
               this.aufgabenliste = response.recordset;
               
               this.idsar= response.recordset
                this.schiff_name= response.recordset[0].schiff_name
                this.arbeitstitel = response.recordset[0].arbeitstitel
                this.beschreibung = response.recordset[0].beschreibung
                this.aufgabe_status = response.recordset[0].aufgabe_status
                this.id_aufgabe = response.recordset[0].id_aufgabe
                this.interne_aufgabe = response.recordset[0].interne_aufgabe
            }
            catch (error) {
                this.msg = "" //error.response.data.schiffsliste;
            }
        },


        async zeige_aufgaben() { 
            try {

               var response='';

              if(this.herkunft=='von_gruppenmodulliste') {
                    const credentials = {
                        id_sar: this.id_sar,
                        id_zuordnung_final_emv_sar: this.id_zuordnung_final_emv_sar,
                        id_zuordnung_final_gmv_sar: this.id_zuordnung_final_gmv_sar,
                        id_aufgabe:  this.id_aufgabe
                    };

                response = await AufgabenService.zeigeaufgabe_per_id_zuordnung_final_gmv_sar(credentials); //************  wenn von aufgabenliste, dann nur explizite Aufgabeanzeigen */
              }
              else {
                    const credentials = {
                        id_sar: this.id_sar,
                        id_zuordnung_final_emv_sar: this.id_zuordnung_final_emv_sar,
                        id_zuordnung_final_gmv_sar: this.id_zuordnung_final_gmv_sar,
                        id_aufgabe:  this.prop_id_aufgabe
                    };

                response = await AufgabenService.zeigeaufgabe_per_id_aufgabe(credentials); //************  wenn von gruppenmodulliste, dann nur alle zugehörigen Aufgabeanzeigen */
              }
               
              this.aufgabenliste = response.recordset;
               
                this.idsar= response.recordset
                this.schiff_name= response.recordset[0].schiff_name
                this.arbeitstitel = response.recordset[0].arbeitstitel
                this.beschreibung = response.recordset[0].beschreibung
                this.aufgabe_status = response.recordset[0].aufgabe_status
                this.id_aufgabe = response.recordset[0].id_aufgabe
                this.interne_aufgabe = response.recordset[0].interne_aufgabe
                this.anzahl_dateien = response.recordset[0].anzahl_dateien
            }
            catch (error) {
                this.msg = "" //error.response.data.schiffsliste;
            }
        },

        onUpdate() {
          const credentials = {
                    id_sar: this.id_sar,
                    id_zuordnung_final_gmv_sar: this.id_zuordnung_final_gmv_sar,
                    id_zuordnung_final_emv_sar: this.id_zuordnung_final_emv_sar,
                };
          const response =  AufgabenService.update_aufgabe(credentials);
                if (response.msg) { //**********  keine gültige Session */
                    this.msg = response.msg;
                }
        },

        formatDate (date) {
          if (!date) return null

          var arr= date.split('-'),
                  year = arr[0],
                  month =  arr[1],
                  day=  arr[2];
          return `${day}.${month}.${year}`
        },
    },

    props: {
          id_sar: { type: Number, required: true },
          prop_id_aufgabe: { type: Number, required: true },
          id_typ_sar: { type: Number, required: true },
          id_zuordnung_final_gmv_sar : { type: Number, required: false },
          id_zuordnung_final_emv_sar : { type: Number, required: false },
          gruppen_modul_bezeichnung:{type: String, required: false},
          einzel_modul_bezeichnung:{type: String, required: false},
          schiff_name:{type: String, required: false},
          anzahl_aufgaben: { type: String, required: false },
          id_gmv: { type: Number, required: false },
          id_emv: { type: Number, required: false },
          herkunft: { type: String, required: false },
          current_user_id: { type: Number, required: false },
          anzahl_aufgaben_dateien: { type: Number, required: false },
          
    },

    components: {
        //  UploadImage,
      // Camera_neu,
      Fotogalerie
}

})
</script>


<style>
.schatten{
  opacity:0.7;
	background: #F8F8F8; 
	border: solid #BDBDBD 1px; 
	box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
	-webkit-box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
	-moz-box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
}

.css_datei_upload{

  width:60%;
}

.calenderfeld{
      width: 177px;
     
      font-size: 0px;
      text-decoration: none;
}

.css_datumsanzeige {
position:relative;
  font-size: 15px;
 
}


.select {
      width: 60px;
      max-height: 60px;
      font-size: 11px;
    }

.right {
 
  float:right;
  margin-right:10%;
}
</style>