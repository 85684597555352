<template>


    <div mdi-view-dashboard style="b order:2px solid blue;">

        <h2 class="primary--text  ma-6 pa-0">Check-und Wartungspunkte</h2>


    <!-- Auswahl     https://codingbeautydev.com/blog/vuetify-select/                append-outer-icon="mdi-ferry"     -->
    <!-- https://vue-select.org/guide/values.html#getting-and-setting -->
    <!-- https://stackoverflow.com/questions/49498674/vuetify-v-select-options-from-json-array-of-objects -->

<!-- ********************** Button Checkliste erstellen ******************** -->
<v-row justify="end" class="ma-0 pa-0 mt-n7" >
    <v-col cols="12" xs="12" class="text-right">
        
              <v-btn class="ma-3"
                rounded
                elevation="5"
                small
                @click="checkliste_abschliessen(selected_id_sar)"
              >
                abschliessen
              </v-btn>
          
   
        
        <v-btn class="ma-3"
        
        rounded
          color="gray"
          elevation="5"
          small
          @click="checkliste_erstellen(selected_id_sar)"
        >
          erstellen
        </v-btn>
    
</v-col>
</v-row>

<h3 class="primary--text  ml-6 pa-0" style="font-weight:normal; margin-top:-20px;" >{{(this.aktueller_schiffsname)}}</h3> 


        <v-row justify="left" class="ma-0 pa-0" >
            <v-col cols="6" xs="12" >
                <v-select  class="mb-0 ml-0 pa-0 black--text "  @change="update_id_sar_global();zeige_checkpunkte(selected_id_sar)" outlined   
                :items="schiffsliste_auswahl"
                return object 
                v-model=selected_id_sar 
                item-key=id_sar_ausgewaehlt_global
                item-text="schiff_name"
                item-value= "id_sar"
                label="wähle SAR-Einheit"> 
                </v-select>
            </v-col>
       
            <v-col cols="6" xs="12" >
                <v-card-title class="mt-n6" >
                    <v-text-field  n5
                        v-model="search_checks"
                        append-icon="mdi-magnify"
                        label="Filter"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
            </v-col>
        </v-row>





        <v-row justify="left" class="ma-0 pa-0 mt-n10" >
           

           <!-- ********************** Zeitraum in Wochen ******************** -->

            <v-col cols="12" xs="12" sm="6">
                    <v-radio-group row label="Zeitraum in Wochen" direction="horizontal" v-model="zeitraum" mandatory   class="small-radio" >
                        
                       <v-radio   class="small-radio"
                       color="primary"
                       font-size="8px"
                            v-for="i in 3"
                            :key="i"
                            :label="`${i}`"
                            :value="i"
                            v-on:click="zeige_checkpunkte(selected_id_sar)"
                        >
                        </v-radio>
                        <v-radio
                            :label="`alle`"
                            :value="4"
                            v-on:click="zeige_checkpunkte(selected_id_sar)"
                        ></v-radio>
                    </v-radio-group>
            </v-col>
     
          


<!-- ********************** Bereichsauswahl ******************** -->
           
           <v-col cols="12" xs="12" sm="6" :class="{'mt-n13': $vuetify.breakpoint.xs, 'mt-0': $vuetify.breakpoint.smAndUp}"> 
                   <v-radio-group row label="Bereich" direction="horizontal" v-model="bereich" mandatory  class="small-radio" >
                   <v-radio label="Brücke" value="Brücke" checked v-on:click="zeige_checkpunkte(selected_id_sar)"></v-radio>
                   <v-radio label="Maschine" value="Maschine" v-on:click="zeige_checkpunkte(selected_id_sar)"></v-radio>
                   <v-radio label="Deck" value="Deck" v-on:click="zeige_checkpunkte(selected_id_sar)"></v-radio>
                   </v-radio-group>
           </v-col>
       
       </v-row>



    <!-- https://vuetifyjs.com/en/components/data-tables/#dense -->
        <v-data-table
            dense
            :headers="headers"
            :items="checkliste"
            :search="search_checks"
            item-key="selected_id_sar"
            sort-by="ampel"
            multi-sort
            
            :class="elevation-1"
            show-group-by
            group-asc
            :items-per-page="25"
            :mobile-breakpoint="2"
            :loading="ladetabelle"
            loading-text="Lade Daten... "
        >


        <template v-slot:item.gruppen_modul_bezeichnung="{ item }" >



            <div style="display: table-cell; padding:5px 0px 5px 0px; max-width:10% !important; overflow:hidden;" class=" schriftgroesse_normal">
                <div  class="d-lg-none" >{{ item.gruppen_modul_bezeichnung  }}<span style="max-width:100 px !important; white-space:pre-wrap; overflow:hidden;border:0px solid green !important;" v-if="item.einzel_modul_bezeichnung"> / {{ item.einzel_modul_bezeichnung  }}</span> </div>
                <div  class="hidden-md-and-down   " style="max-width:320 px !important; " >{{ item.gruppen_modul_bezeichnung  }}<span v-if="item.einzel_modul_bezeichnung">/{{ item.einzel_modul_bezeichnung  }}</span> </div>

            

            <div class=" d-lg-none   schriftgroesse_normal"  style=" padding:5px 0px 5px 0px; max-width:170px; color:#669999; font-weight:bold; white-space:pre-wrap; border:0px solid blue !important;">{{item.bezeichnung_check_sar_vorlage.substring(0,140)}}</div>
            <div class=" hidden-md-and-down   schriftgroesse_normal" style=" padding:5px 0px 5px 0px;  color:#669999; font-weight:bold; border:0px solid green !important;">{{item.bezeichnung_check_sar_vorlage}}</div>


           <div style="color:#669999; font-weight:normal; " class=" schriftgroesse_normal">{{  item.bezeichnung_intervall_vorlage_1}} <span v-if="item.bezeichnung_intervall_vorlage_2">/ {{  item.bezeichnung_intervall_vorlage_2}} </span></div>


           <div style="display: table-row; " class="d-lg-none">
            <span style="font-size:11px; color:#669999">Fälligkeit:</span> 
                <div v-if="item.datum_naechster_check" style=" color:gray; font-size:13px; display: table-cell; font-weight:bold; " >{{formatDatum(item.datum_naechster_check.substr(0, 10))}} </div>
                <div v-if="item.betriebsstunden_naechster_check" style=" padding-left:5px !important;color:gray; font-size:13px;font-weight:bold;  display: table-cell;" >/Std.:{{item.betriebsstunden_naechster_check}} </div>
            </div>

             
            </div>
        </template> 
        

          <!--###############################################################   bei checkboxen muss es  input-value anstelle von value heißen !!!!!!!      -->
             <template v-slot:item.ok_wert="{ item }">
                <div  style="max-width:30px" v-if="item.bezeichnung_check_sar_vorlage.includes('Boot slippen') !==true">
                    <v-checkbox class=" m-0"
                                color="#669999"
                                v-model="item.ok_wert"
                                :value="true"
                                input-value="value"
                                @change="update_checkbox(item.id_check_sar,item.ok_wert)"
                    ></v-checkbox>
                </div>
             </template>                                          

             
             <template v-slot:item.betriebsstunden="{ item }">
                <div  v-if="item.betriebsstunden_naechster_check"  style="font-size:12px;  max-width:30px;  padding:0px; margin:0px;">
                    <div v-if="item.betriebsstunden" style=" color:gray; font-size:12px; display: table-row; padding:0px !important;  margin:0px !important; " >
                                <v-text-field
                                    v-model="item.betriebsstunden"
                                    @change= "erhalte_wert_von_textfeld([index, $event,item.id_check_sar,'id_check_sar','betriebsstunden','tbl_check_sar', item.id_zuordnung_final_gmv_sar])"
                                    hide-details="auto"
                                    class="pa-0    css-text-gray schriftgroesse_kurztext"
                                    style="width:40px !important;"
                                    ></v-text-field>
                    </div>
                </div>
            </template>    



            <!-- *************** AMPEL    -- sichtbar bei großer Auflösung  --  ************************** id_intervall_einheit: 1 = Std, 2=Tage, 3=Wochen, 4=Monate  -->
           
            <template v-slot:item.ampel="{ item }" >
               <!-- unsichtbar wegen Sortierung -->
               <!-- <div v-if="item.datum_naechster_check" style=" color:transparent; font-size:0px; display: table-cell; font-weight:bold; " >{{item.datum_naechster_check}} </div> -->
               <!-- ENDE unsichtbar wegen Sortierung -->


                <div  style="height:auto;width:auto;" v-html=" funktion_ampel (item.id_intervall_einheit_1, item.wert_intervall_vorlage_1, formatDatum(item.datum_naechster_check.substr(0, 10)), item.ampel_wert_intervall_vorlage_gruen_1, item.ampel_wert_intervall_vorlage_gelb_1,  item.ampel_wert_intervall_vorlage_rot_1, item.betriebsstunden, item.betriebsstunden_naechster_check,  item.id_intervall_einheit_2, item.wert_intervall_vorlage_2,  item.ampel_wert_intervall_vorlage_gruen_2, item.ampel_wert_intervall_vorlage_gelb_2, item.ampel_wert_intervall_vorlage_rot_2 ) "></div> 
            </template> 



            <template v-slot:item.datum_naechster_check="{ item }" >
                <div style="display: inline-block;    margin-top:20px; ;   ">
                    <div class="d-md" v-if="item.datum_naechster_check" style="  float:left;position:relative; float:left; color:transparent;font-size:0px; height:1px; width:1px; margin-top:-5px !important;">{{formatDatum(item.datum_naechster_check.substr(0, 10)).substr(6, 4)}}{{formatDatum(item.datum_naechster_check.substr(0, 10)).substr(3, 2)}}{{formatDatum(item.datum_naechster_check.substr(0, 10)).substr(0, 2)}}</div>
                    <div class="d-md" v-if="item.datum_naechster_check" style="display: inline-block; width:25px;  float:left; color:gray; font-size:14px; " >{{formatDatum(item.datum_naechster_check.substr(0, 10))}} </div>
                    <div class="d-md" v-if="item.betriebsstunden_naechster_check" style="display: inline-block; width:75px; margin-left:70px; float:right; color:gray; font-size:14px; " >Std.: {{item.betriebsstunden_naechster_check}} </div>
                </div>
            </template> 



            <!-- ************  macht aus der bereichsüberschrift Großbuchstaben *********** -->
            <!-- <template v-slot:header.Bereich="{ header }">
                <span style="font-weight:bold; color:black">{{ header.text.toUpperCase() }} </span>
            </template> -->

            <!--************** Ausklapp Zeile mit weiteren Infos **************-->
             <!-- <template v-if: item.bezeichnung   v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                       interne Bezeichnung: {{ item.bezeichnung }}  - Seriennummer: {{ item.seriennummer }}    
                    </td>
             </template> -->

             <template v-slot:item.id_zuordnung_final_gmv_sar="{ item }" >
                <div class=" flex-nowrap d-flex align-center">
                    <Popup_infos @emit_closepopup ="popupgeschlossen"  title="Infos anzeigen" :id_sar =item.id_sar :current_user_id=current_user_id  :id_typ_sar =item.id_SAR_Typ  :schiff_name =item.schiff_name  :anzahl_infos=item.anzahl_infos  :id_gmv =item.id_gmv :id_emv =0  :gruppen_modul_bezeichnung =item.GM  :einzel_modul_bezeichnung =0  :id_zuordnung_final_gmv_sar = item.id_zuordnung_final_gmv_sar   :id_zuordnung_final_emv_sar =0   class="ma-3"></Popup_infos> 
                </div>

            </template>

        </v-data-table>
        
    </div> 

</template>


<script>

import SchiffeService from '../services/SchiffeService';
import Popup_infos from '../components/Popup_infos.vue';

import CheckpunkteService from '@/services/CheckpunkteService.js';
import Checkpunkte_erstellenService from '@/services/Checkpunkte_erstellenService.js';
import Checkpunkte_abschliessenService from '@/services/Checkpunkte_abschliessenService.js';


import AuthService from '@/services/AuthService.js';

import AufgabenService from '@/services/AufgabenService.js';

 import { EventBus } from '../main';

export default {
    data() {
            return {
                schiffsliste:"",
                schiffsliste_auswahl:"",
                id_sar:'',
                aktueller_schiffsname: '',
               // selected_id_sar_checkpunkt: localStorage.id_sar_ausgewaehlt_global,
                selected_id_sar: localStorage.id_sar_ausgewaehlt_global,
                selected_name_sar : localStorage.name_sar_ausgewaehlt_global,
                in_liste_schiff_alt:'',
                current_user_id:'',
                ok_wert:false,
                search_checks: '',
                zeitraum:'1',
                bereich:'Maschine',
                headers: [
                // {
                //     text: 'Bereich',
                //     align: 'start d-none d-sm-table-cell',  //********  d-none d-lg-table-cell   = wird nur bei größeren Viewports (lg = large) angezeigt :   https://stackoverflow.com/questions/59871140/vuejs-how-to-hide-some-data-table-columns-on-mobile-size */
                //     value: 'Bereich',
                //     groupable: true,
                // },
                { text: 'Modul', value: 'gruppen_modul_bezeichnung', align: 'start', groupable: false },
              

                { text: 'EM', value: 'einzel_modul_bezeichnung', align: 'left   d-none', groupable: false},  //********  d-none heißt: ausblenden !! Ist aber wichtig für FILTERUNG       d-md-table-cell   = wird nur bei größeren Viewports (md=Medium)angezeigt :
                { text: 'Check-Bez.', value: 'bezeichnung_check_sar_vorlage', align: 'left    d-none' , groupable: false,  visible: false},  //********  d-none d-md-table-cell   = wird nur bei größeren Viewports (md=Medium)angezeigt :
                { text: 'Intervall', value: 'bezeichnung_intervall_vorlage_1', align: 'left    d-none' , groupable: false,  visible: false},  //********  d-none d-md-table-cell   = wird nur bei größeren Viewports (md=Medium)angezeigt :

               
                // { text: '', value: 'data-table-expand' , align: 'start hidden-xs-and-down   hidden-md-and-up ', sortable: false, groupable: false},  //********  verstecken ab mittleren Viewports und höher :  hidden-md-and-up
                { text: 'OK', value: 'ok_wert', align: 'left  ' ,groupable: false}, 
                { text: 'Std.', value: 'betriebsstunden', align: 'left  ' , groupable: false}, 
               
                { text: 'Status', value: 'ampel', align: 'left ', groupable: false}, 

                { text: 'näch. Datum / Betr.Std.', value: 'datum_naechster_check', align: 'left start hidden-md-and-down   ', groupable: false}, 
                // { text: 'näch. Betr.Std.', value: 'betriebsstunden_naechster_check', align: 'left start hidden-md-and-down    ' , groupable: false}, 
                // { text: '', value: 'data-table-expand' , align: 'start hidden-xs-and-down   hidden-md-and-up ', sortable: false, groupable: false},  //********  verstecken ab mittleren Viewports und höher :  hidden-md-and-up

                ],
                expanded: [],
                singleExpand: true, //**********  für Tabellenzeie Info ausklappen (immer nur eine oder beliebig viele)) */
                checkliste: [],
               
            };
    },
    methods: {   


        async update_id_sar_global() {
          //*** wenn Sar-Einheit in Dropdown ausgewählt wurde, dann diesen Wert in localStorage speichern //******  globale session variabel setzen  (siehe in App.vue  / mounted und watch ) */
            if(typeof(this.selected_id_sar) !== "undefined" && this.selected_id_sar !=='' ) { 
                localStorage.id_sar_ausgewaehlt_global = this.selected_id_sar  
                EventBus.$emit('sende_globale_id_sar',this.selected_id_sar)
            } 

        }
,



        async checkliste_erstellen() {
               
               this.ladetabelle = true
               try {
                   var credentials = {
                      // id_sar: uebergabe_selected_id_sar, //localStorage.id_sar_ausgewaehlt_global,
                      id_sar: localStorage.id_sar_ausgewaehlt_global,

                                          };
                    await Checkpunkte_erstellenService.checkpunkte_erstellen(credentials);
                    this.zeige_checkpunkte(this.selected_id_sar);                
               }
               catch (error) {
               // this.msg = error.response.data.aufgabenliste;
               }
        },


        async checkliste_abschliessen() {
              // alert(this.selected_id_sar)
               this.ladetabelle = true
               try {
                   var credentials = {
                      // id_sar: this.selected_id_sar,//localStorage.id_sar_ausgewaehlt_global,
                       id_sar: localStorage.id_sar_ausgewaehlt_global,
                                          };
                    await Checkpunkte_abschliessenService.checkpunkte_abschliessen(credentials);
                    this.zeige_checkpunkte(this.selected_id_sar);                
               }
               catch (error) {
               // this.msg = error.response.data.aufgabenliste;
               }
        },


        //*******************  AMPEL-Funktion */
            funktion_ampel( id_intervall_einheit_1, wert_intervall_vorlage_1, datum_naechster_check, ampel_wert_gruen_1, ampel_wert_gelb_1, ampel_wert_rot_1, betriebsstunden, betriebsstunden_naechster_check, id_intervall_einheit_2, wert_intervall_vorlage_2,  ampel_wert_gruen_2, ampel_wert_gelb_2, ampel_wert_rot_2 )
            {
                // id_intervall_einheit: 1 = Std, 2=Tage, 3=Wochen, 4=Monate, 5= Jahr


                //************  allgemeine Parameter */
                  //var css_style='border-color';
                var css_style='background-color';
                var ampelzwischenwert1 = css_style +':red '  //= rot
                var anzeige ='';
                var differenz ;
                var tag_check =''; 
                var monat_check ='';
                var jahr_check ='';
                var datum_check ='';
                var datum_heute ='';
                var diff = 0;
                var vorgabewert ='';
                var ampelwert ='';
                var null_linie_betriebsstunden = 0;
                var ampelstufe_studen ='';
                var ampelstufe_datum ='';

                var ampelergebnis_zahl=0;

                //***** erstes Feld ist ein Datumsfeld  */
                if (id_intervall_einheit_1=='2' || id_intervall_einheit_1=='3' || id_intervall_einheit_1=='4' || id_intervall_einheit_1=='5')
                {
                             tag_check= datum_naechster_check.substring(0, 2);
                             monat_check= datum_naechster_check.substring(3, 5);
                             jahr_check= datum_naechster_check.substring(6, 10);
                             datum_check = new Date(monat_check +'/'+tag_check +'/'+ jahr_check); 
                             datum_heute = new Date();   

                            //****  wenn Zeiteinheit / Datum   --  diff in Millisekunden  */
                                diff =  datum_check.getTime()- datum_heute.getTime() ; 
                                differenz = Math.round(diff / (1000 * 60 * 60 * 24)) +1;  //in Tagen

                            if (id_intervall_einheit_1 =='3') {differenz = (differenz / 7).toFixed(1)}  //in Wochen
                            if (id_intervall_einheit_1 =='4') {differenz = (differenz / 30).toFixed(1)}  //in Monaten
                            if (id_intervall_einheit_1 =='5') {differenz = (differenz / 365).toFixed(1)}  //in Jahren

                            vorgabewert = wert_intervall_vorlage_1 ; //'****  Vorgabewert (z.B. 12 Wochen)
                            ampelwert = vorgabewert - differenz ;  // '****  ampelwert = vorgabewert (12)  -  datediff [heute-nächster Check]  (z.B. 1 Woche)   --->   ampelwert = 12-1 = 11  ----> das wäre bei Vorgabewert 11 für gelb die GELBE Ampel

                                    if (differenz <= 0) {ampelzwischenwert1 = css_style +':red';  ampelstufe_datum = '3' }  // wenn differenz im negativen Bereich, dann immer rot
                                    else
                                    {
                                        if (parseInt(ampelwert) < ampel_wert_rot_1) {       ampelstufe_datum = '2' } // gelb
                                        if ((ampelwert<=ampel_wert_gelb_1 && ampelwert >    ampel_wert_gruen_1) || differenz == 0) { ampelstufe_datum = '2' } // gelb
                                        if (parseInt(ampelwert) <= ampel_wert_gruen_1) {    ampelstufe_datum = '1' } // grün
                                    }
                }
                

                //***** erstes Feld sind Betriebsstunden  */
                if (id_intervall_einheit_1=='1' )
                {

                    //****  wenn Betriebsstunden */
                    if(betriebsstunden > 0 && betriebsstunden != typeof null && betriebsstunden_naechster_check > 0 && betriebsstunden_naechster_check != typeof null) {
                       null_linie_betriebsstunden = betriebsstunden_naechster_check - ampel_wert_rot_1;
                       diff = betriebsstunden - null_linie_betriebsstunden;
                    }
                            
                    if (diff >= ampel_wert_rot_1) {     ampelstufe_studen = '3' }
                    if (diff < ampel_wert_rot_1) {      ampelstufe_studen = '2' }
                    if (diff == ampel_wert_gelb_1) {    ampelstufe_studen = '2' }
                    if (diff < ampel_wert_gelb_1) {     ampelstufe_studen = '1' }
                    if (diff <= ampel_wert_gruen_1) {   ampelstufe_studen = '1' }


                   // anzeige =`<div style='height:20px;width:20px; border-radius: 50%; border:1px solid black;${ampelzwischenwert1}' ></div>`
                   anzeige = anzeige  +' Betriebsstunden 1 ( ampelstufe_studen ' + ampelstufe_studen +  ' )  ' 

                }



                //***** zweites Feld ist ein Datumsfeld  */
                if (id_intervall_einheit_2=='2' || id_intervall_einheit_2=='3' || id_intervall_einheit_2=='4' || id_intervall_einheit_2=='5')
                {
                             tag_check= datum_naechster_check.substring(0, 2);
                             monat_check= datum_naechster_check.substring(3, 5);
                             jahr_check= datum_naechster_check.substring(6, 10);
                             datum_check = new Date(monat_check +'/'+tag_check +'/'+ jahr_check); 
                             datum_heute = new Date();   
                             diff = 0;

                            //****  wenn Zeiteinheit / Datum   --  diff in Millisekunden  */
                            diff =  datum_check.getTime()- datum_heute.getTime() ; 
                           
                               //****  wenn Zeiteinheit / Datum   --  diff in Millisekunden  */
                                diff =  datum_check.getTime()- datum_heute.getTime() ; 
                                differenz = Math.round(diff / (1000 * 60 * 60 * 24)) +1;  //in Tagen

                            if (id_intervall_einheit_2 =='3') {differenz = (differenz / 7).toFixed(1)}  //in Wochen
                            if (id_intervall_einheit_2 =='4') {differenz = (differenz / 30).toFixed(1)}  //in Monaten
                            if (id_intervall_einheit_2 =='5') {differenz = (differenz / 365).toFixed(1)}  //in Jahren


                             vorgabewert = wert_intervall_vorlage_2 ; //'****  Vorgabewert (z.B. 12 Wochen)
                             ampelwert = vorgabewert - differenz ;  // '****  ampelwert = vorgabewert (12)  -  datediff [heute-nächster Check]  (z.B. 1 Woche)   --->   ampelwert = 12-1 = 11  ----> das wäre bei Vorgabewert 11 für gelb die GELBE Ampel
                        
                                    if (differenz <= 0) {ampelzwischenwert1 = css_style +':red' ; ampelstufe_datum = '3' }  // wenn differenz im negativen Bereich, dann immer rot
                                    else
                                    {
                                        if (parseInt(ampelwert) < ampel_wert_rot_2) {  ampelstufe_datum = '2' } // gelb
                                        if ((ampelwert<=ampel_wert_gelb_2 && ampelwert > ampel_wert_gruen_2) || differenz == 0) {  ampelstufe_datum = '2' } // gelb
                                        if (parseInt(ampelwert) <= ampel_wert_gruen_2) {  ampelstufe_datum = '1' } // grün
                                    }
                      anzeige = anzeige  +' Datum 2 ( ampelstufe_datum '+ ampelstufe_datum+'  ampelwert: '+ampelwert+'    ampel_wert_rot_2: '+ampel_wert_rot_2+'  )  '
                        
                }
                

                //***** zweites Feld sind Betriebsstunden  */
                if (id_intervall_einheit_2=='1' )
                {

                    //****  wenn Betriebsstunden */
                    if(betriebsstunden > 0 && betriebsstunden != typeof null && betriebsstunden_naechster_check > 0 && betriebsstunden_naechster_check != typeof null) {
                       null_linie_betriebsstunden = betriebsstunden_naechster_check - ampel_wert_rot_2;
                       diff = betriebsstunden - null_linie_betriebsstunden;
                    }
                            
                    if (diff >= ampel_wert_rot_2) {     ampelstufe_studen = '3' }
                    if (diff <  ampel_wert_rot_2) {      ampelstufe_studen = '2' }
                    if (diff == ampel_wert_gelb_2) {    ampelstufe_studen = '2' }
                    if (diff <  ampel_wert_gelb_2) {     ampelstufe_studen = '1' }
                    if (diff <= ampel_wert_gruen_2) {   ampelstufe_studen = '1' }

                  
                  anzeige = anzeige  +' Betriebsstunden 2 ( ampelstufe_studen ' + ampelstufe_studen +' )  ' 

                }


                if (ampelstufe_studen >=ampelstufe_datum) {
                    ampelergebnis_zahl = ampelstufe_studen}
                    else {
                    ampelergebnis_zahl = ampelstufe_datum}

                  anzeige=  anzeige + '  ampelergebniszahl:  '+ampelergebnis_zahl;

                  if (ampelergebnis_zahl==3) {
                    ampelzwischenwert1= css_style +':red; ' ;
                  }

                  switch(ampelergebnis_zahl) {
                    case '3':
                        ampelzwischenwert1= css_style +':red; ' ;
                        break;
                    case '2':
                    ampelzwischenwert1= css_style +':yellow; ' ;
                        break;
                    case '1':
                    ampelzwischenwert1= css_style +':green; ' ;
                        break;
                    default:
                    ampelzwischenwert1= css_style +':blue; ' ;
                } 

                //anzeige = anzeige + `<div style='height:20px;width:20px; border-radius: 50%; border:1px solid black;${ampelzwischenwert1}' >   ${ampelergebnis_zahl}   </div>`
                anzeige = `<div style='height:20px;width:20px; border-radius: 50%; border:1px solid black;${ampelzwischenwert1}' ></div>`
                return anzeige ;



                    
            }
            
            ,

            

        erhalte_wert_von_textfeld(index) {
            this.update_feld(index)
        },

        

    
        async  update_feld(index) {
        try {

            const credentials = {
                wert: index[1],
                id: index[2],
                id_bezeichnung: index[3],
                feld: index[4],
                tabelle: index[5],
                id_zuordnung_final_gmv_sar: index[6] 
            };
            
            const response = await AufgabenService.update_text_feld(credentials);
            this.zeige_checkpunkte(this.selected_id_sar) 

            if (response.msg) { //**********  keine gültige Session */
                this.msg = response.msg;
            }
        }
        catch (error) {
            this.msg = "Fehler" //error.response.data.schiffsliste;
        }
        },




         //**************************************************************https://codepen.io/jkarczm/pen/LYbYmxz?editors=1010 */
         async  update_checkbox(id_check_sar,value) {
            try {
                const credentials = {
                    wert: value,
                    id: id_check_sar,
                    id_bezeichnung: 'id_check_sar',
                    feld: 'ok_wert',
                    tabelle: 'tbl_check_sar',
                    user_id:this.current_user_id  //#############################  noch in API einbauen !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                };

                const response = await AufgabenService.update_checkbox_feld(credentials);
                
              //'***************** schickt emit an Parent, damit Liste Aufgaben neu geladen wird (mit neuer Anzahl Aufgaben))
                // if(this.herkunft == "von_aufgabenliste") {this.$emit('emit_closepopup', 'von_aufgabe_aufgabenliste');}
//                   this.zeige_gruppenmodule(localStorage.id_sar_ausgewaehlt_global);
// 
                if (response.msg) { //**********  keine gültige Session */
                    this.msg = response.msg;
                }
            }
            catch (error) {
                this.msg = "Fehler" //error.response.data.schiffsliste;
            }
        },





        formatDatum (date) {
          if (!date) return null

          var arr= date.split('-'),
                  year = arr[0],
                  month =  arr[1],
                  day=  arr[2];
          return `${day}.${month}.${year}`
        },


        async hole_user_id() {
                try {
                    const credentials = {
                    username: this.username,
                    password: this.password
                    };
                    const response = await AuthService.hole_user_id(credentials);
                    this.current_user_id = response;
                   // alert('521  ' + this.current_user_id);
                    this.zeige_sar_einheit_auswahl();
                }
                catch(err){
                    console.log('token is null: ',err);
                }
        },


            popupgeschlossen(){
                this.zeige_checkpunkte(this.selected_id_sar);
            },


            async zeige_sar_einheit_auswahl() {
                //alert('### '+this.selected_id_sar);
                try {
                    const credentials = {
                        userId:this.current_user_id 
                       // id_user: id_user
                    };
                    
                    const response = await SchiffeService.zeigeschiffe(credentials);
                    if (typeof response.msg !=='undefined'){this.$router.push("/login")} //****  wenn keine Anmeldung */
                    this.schiffsliste_auswahl = response.recordset;
                  //  alert('#+++# '+response.data.id_sar);
                    //this.selected_id_sar = response.data.id_sar;  //************* Michael  */
                    
                }
                catch (error) {
                // this.msg = error.response.data.aufgabenliste;
                }
            },


            async zeige_checkpunkte() {
               
                //*** wenn Sar-Einheit in Dropdown ausgewählt wurde, dann diesen Wert in localStorage speichern //******  globale session variabel setzen  (siehe in App.vue  / mounted und watch ) */
                //if(typeof(this.selected_id_sar_checkpunkt) !== "undefined" && this.selected_id_sar_checkpunkt !=='' && this.zeitraum !=='') { localStorage.id_sar_ausgewaehlt_global = this.selected_id_sar_checkpunkt  } 
                
                this.ladetabelle = true
                try {
                    var credentials = {
                       // id_sar: uebergabe_selected_id_sar,//localStorage.id_sar_ausgewaehlt_global,
                        id_sar: localStorage.id_sar_ausgewaehlt_global,

                        zeitraum: this.zeitraum,
                        bereich: this.bereich,
                    };

                    // Hier werden die Daten abgerufen (aufruf axios in script)
                    const response = await CheckpunkteService.zeigecheckpunkte(credentials);

                    // if (typeof response.msg !=='undefined'){this.$router.push("/login")} //****  wenn keine Anmeldung */ 

                    this.ladetabelle = false  //*****  Lade Daten Symbol (Stfreifen)) */
                    this.checkliste = response.recordset;
                    
                    //************* setze den ausgewählten Schiffsnamen *******/
                   // this.selected_id_sar_checkpunkt = this.checkliste[1].id_sar;
                    this.ok_wert = this.checkliste[1].ok_wert;
                    this.aktueller_schiffsname = this.checkliste[1].schiff_name;

                }
                catch (error) {
                // this.msg = error.response.data.aufgabenliste;
                }
            },


            zum_login() {
                this.$router.push("/login");
            }

    },


//     computed: {
//         ampel: function () {
//             return this.interval_1+ '##'+ this.wert_1
//         }
// 
//    
//     },

    //******************************* wichtig für update Select-Feld und Tabelle danach (wenn SAR-Einheit wechselt) , dann wird dies überall übernommen ***** Michael    */
    watch: {
        selected_id_sar: function (neuer_wert) {
            this.zeige_sar_einheit_auswahl();
            this.zeige_checkpunkte(neuer_wert) ;
            }
    },



    created() {

            //  wenn id_sar_ausgewaehlt_global   in localStorage   bei  Modulaufruf  nicht leer ist, dann     zeige_einzelmodule(localStorage.id_sar_ausgewaehlt_global)    aufrufen
           // if(typeof(localStorage.id_sar_ausgewaehlt_global) !== "undefined" && localStorage.id_sar_ausgewaehlt_global !=='' ) {
                
               // this.selected_id_sar_checkpunkt  = localStorage.id_sar_ausgewaehlt_global;
                
                
            //}
            //************* wenn auf Seite Schiffsliste ein (anderes) Schiff ausgewählt wird ) ***** Michael  */
            EventBus.$on('sende_globale_id_sar', eventbusparameter => {
            this.eventbusmsg =eventbusparameter;
            console.log('Custom event triggered - empfangen von checkpunkt - zeile 723!!!' + this.eventbusmsg);
            localStorage.id_sar_ausgewaehlt_global = this.eventbusmsg;
            this.selected_id_sar=this.eventbusmsg;
            
            })
        

            if (!this.$store.getters.isLoggedIn) {
                this.$router.push('/login');
            }
           
            this.zeitraum=1;    
            this.hole_user_id()//.then(this.zeige_sar_einheit_auswahl())//.then( this.zeige_checkpunkte());

            if(typeof(localStorage.id_sar_ausgewaehlt_global) !== "undefined" && localStorage.id_sar_ausgewaehlt_global !=='' ) {
                this.selected_id_sar  = localStorage.id_sar_ausgewaehlt_global
               // alert(localStorage.id_sar_ausgewaehlt_global)
               // this.zeige_checkpunkte(localStorage.id_sar_ausgewaehlt_global )
               // this.zeige_sar_einheit_auswahl();
            }

            
                this.zeige_sar_einheit_auswahl();
                this.zeige_checkpunkte(this.id_sar_ausgewaehlt_global);
    },

    components: { 
     
      Popup_infos
    }
};

</script>


<style scoped>

.small-radio >>> label {  /**********************************  die    >>>    sind wichtig, sonst klappt es nicht */
  font-size: 12px !important; 
  margin-left: -7px;
}


@media screen and (max-width : 500px)
    {
    .schriftgroesse_normal {
    font-size:11px;
    }
}


@media screen and (min-width : 501px)
    {
    .schriftgroesse_normal {
    font-size:12px;
    }
}

@media screen and (min-width : 701px)
    {
    .schriftgroesse_normal {
    font-size:14px;
    }
}

</style>
