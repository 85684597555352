import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({

    // theme: {
    //     themes: {
    //       light: {
    //         primary: '#ba150f',
    //         secondary: '#b30505',
    //         accent: '#8c9eff',
    //         error: '#b71c1c',
    //         active: 'red'
    //       },
    //     },
    //   },
   
    theme: {
      themes: {
        light: {
          primary: '#ba150f',
          secondary: '#b30505',
          accent: '#8c9eff',
          error: '#b71c1c',
          active: 'red'
        },
      },
    },
});
