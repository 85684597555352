<template>

  <div class="text-center">


    <v-dialog @keydown.esc="dialog = false"  max-width="600">

          <template v-slot:activator="{on}">
            <v-btn icon dark x-small v-on="on" @click="zeige_schiff_update" ><v-icon class="grey--text" >mdi-pencil</v-icon></v-btn>
          </template>

          <v-form ref="form"  @submit.prevent="onUpdate">
          
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>{{schiffsname}}</v-card-title>
                   
                  <v-card-text class="pa-3">
                        <v-text-field
                          label="IMO"
                          v-model="imo"
                          hide-details="auto"
                          class="pa-5"
                        ></v-text-field>
                        <v-text-field
                          label="MMSI"
                          v-model="mmsi"
                          hide-details="auto"
                          class="pa-5"
                        ></v-text-field>
                        <v-text-field
                          label="Werft"
                          v-model="schiff_werft"
                          hide-details="auto"
                          class="pa-5"
                        ></v-text-field>

                        <span  class="ml-5 black--gray ">Station</span><br>
                        <span  style="font-size:17px; color:black; margin-left:20px;">{{ stationsname }}</span>

                        <v-select  class="ma-10 ml-0 pa-0 black--text "  outlined   @click="zeige_stationen_auswahl()"
                        :items="stationen_auswahl"
                        return object 
                        v-model= selected_id_station
                        item-text="stationsname"
                        item-value= "id_station"
                        label="Station ändern"> 
                        </v-select>

                    <v-btn type="submit" color="secondary my-4">
                      Speichern
                    </v-btn>

                  </v-card-text>

            </v-card>

          </v-form>

        </v-dialog>

  </div>

</template>

<script>

import SchiffeService from '@/services/SchiffeService.js';
import StationenService from '@/services/StationenService.js';


export default ({

data() {
        return {
            schiffsliste: "",
            schiffsname:"",
            imo: "",
            mmsi:"",
            msg:"",
            schiff_werft:"",
            station:"",
            stationen_auswahl:"",
            
        };
    },

 methods: {

              //'***************** schickt emit an Parent, damit Liste GM neu geladen wird (mit neuer Anzahl Aufgaben))
              closePopup_schiff() {
                  this.$emit('emit_closepopup_schiff', 'von_popup_schiff');
                },



        async zeige_schiff_update() {
            try {

               const credentials = {
                    id_sar: this.id_sar,
                    imo: this.imo,
                    mmsi: this.mmsi,
                    schiff_werft: this.schiff_werft
                };

                const response = await SchiffeService.zeigeschiff_per_id(credentials);
                if (response.msg) { //**********  keine gültige Session */
                    this.msg = response.msg;
                    setTimeout(this.$router.push("/login"), 4000);
                }
                //this.schiffsliste = response.recordset;
                this.idsar= response.recordset
                this.schiffsname= response.recordset[0].schiff_name
                this.imo= response.recordset[0].imo
                this.mmsi= response.recordset[0].mmsi
                this.schiff_werft= response.recordset[0].schiff_werft
                this.selected_id_station= response.recordset[0].id_station
            }
            catch (error) {
                this.msg = error.response.data.schiffsliste;
            }
        },

        onUpdate() { 
          const credentials = {
                    id_sar: this.id_sar,
                    imo: this.imo,
                    mmsi: this.mmsi,
                    schiff_werft: this.schiff_werft,
                    id_station: this.selected_id_station
                };
               // alert(this.selected_id_station);
          const response =  SchiffeService.update_schiff(credentials);
                if (response.msg) { //**********  keine gültige Session */
                    this.msg = response.msg;
                   
                }
                this.closePopup_schiff();
        },


        async zeige_stationen_auswahl() {
            
            try {

                const credentials = {
                   // id_user: id_user
                };

                const response = await StationenService.zeigeallestationen(credentials);
                if (typeof response.msg !=='undefined'){this.$router.push("/login")} //****  wenn keine Anmeldung */
                this.stationen_auswahl = response.recordset;
            }
            catch (error) {
            // this.msg = error.response.data.aufgabenliste;
            }
        },


    },

created() {


  //this.zeige_stationen_auswahl()

},

props: {
    id_sar: { type: Number, required: true },
    stationsname: { type: String, required: false },
    aktive_schiff_id_popup_ausgewaehlt : { type: Number, required: false },
},

   
})
</script>


<style>
.schatten{
  opacity:0.7;
	background: #F8F8F8; 
	border: solid #BDBDBD 1px; 
	box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
	-webkit-box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
	-moz-box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
}



.right {
 
  float:right;
  margin-right:10%;
}
</style>