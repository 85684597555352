var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"mdi-view-dashboard":""}},[_c('h2',{staticClass:"primary--text  ma-6 pa-0"},[_vm._v("Stationsverbräuche")]),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"left"}},[_c('v-col',{attrs:{"sm":"4"}},[_c('v-select',{staticClass:"mb-0 ml-0 pa-0 black--text ",attrs:{"outlined":"","items":_vm.stationslistesliste_auswahl,"return":"","object":"","item-text":"stationsname","item-value":"id_station","label":"wähle Station"},on:{"change":function($event){return _vm.zeige_stationsverbraeuche()},"click":function($event){_vm.zeige_stationen_auswahl(), _vm.zeige_stationsverbraeuche()}},model:{value:(_vm.selected_id_station),callback:function ($$v) {_vm.selected_id_station=$$v},expression:"selected_id_station"}})],1),_c('v-col',{attrs:{"sm":"4 "}},[_c('v-card-title',{staticClass:"mt-n6"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":140,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"calenderfeld",staticStyle:{"color":"red","font-size":"15px"},attrs:{"label":"Datum","persistent-hint":"","prepend-icon":"mdi-calendar"},model:{value:(_vm.neuesdatum_fuer_anzeige),callback:function ($$v) {_vm.neuesdatum_fuer_anzeige=$$v},expression:"neuesdatum_fuer_anzeige"}},on))]}}]),model:{value:(_vm.zeige_kalender),callback:function ($$v) {_vm.zeige_kalender=$$v},expression:"zeige_kalender"}},[_c('v-date-picker',{attrs:{"header-color":"primary","locale":"de"},on:{"input":function($event){_vm.zeige_kalender = false},"change":function($event){return _vm.erhalte_datum([_vm.index, $event])}},model:{value:(_vm.datum),callback:function ($$v) {_vm.datum=$$v},expression:"datum"}})],1)],1),_c('div',{staticStyle:{"position":"relative","float":"right !important","z-index":"999999999999","width":"auto","margin":"-80px 10px 0px 0px"}},[_c('v-icon',{attrs:{"color":"green darken-2"},on:{"click":function($event){return _vm.stationsverbrauch_einfuegen()}}},[_vm._v("mdi-pencil-plus")])],1)],1)],1),_c('v-data-table',{class:_vm.elevation-1,attrs:{"dense":"","headers":_vm.headers,"items":_vm.stationsverbrauchsliste,"item-key":"id_station_verbrauch","multi-sort":"","items-per-page":25,"mobile-breakpoint":2,"loading":_vm.ladetabelle,"loading-text":"Lade Daten... "},scopedSlots:_vm._u([{key:"item.bemerkungen_vorlage",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"padding":"7px 0px 5px 0px","width":"100px","font-weight":"bold"}},[_vm._v(_vm._s(item.bemerkungen_vorlage)+" ")]),_c('div',{staticClass:" schriftgroesse_normal",staticStyle:{"padding":"1px 0px 7px 0px","width":"100px","color":"#669999"}},[_vm._v(" "+_vm._s(item.verbraucher_beschreibung)+" ")]),_c('div',{staticClass:" schriftgroesse_normal",staticStyle:{"padding":"1px 0px 7px 0px","width":"100px","color":"#669999"}},[_vm._v(" "+_vm._s(item.zaehler_nummer)+" ")])]}},{key:"item.ablesedatum",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"0px","color":"transparent"}},[_vm._v(_vm._s(item.ablesedatum.substr(8, 2) + '.' + item.ablesedatum.substr(5, 2)+ '.' +item.ablesedatum.substr(0, 4)))]),_c('div',{staticClass:" schriftgroesse_kurztext",staticStyle:{"display":"table-cell","max-width":"20%","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.ablesedatum.substr(8, 2) + '.' + item.ablesedatum.substr(5, 2)+ '.' +item.ablesedatum.substr(0, 4))+" ")])]}},{key:"item.zaehlerstand1",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"10px","width":"30px","margin-top":"30px","float":"left"}},[_vm._v(_vm._s(item.verbrauch_einheit))]),_c('div',{staticStyle:{"white-space":"nowrap","float":"left","width":"100px"}},[_c('v-text-field',{staticClass:"pa-2    css-text-rot ",staticStyle:{"width":"100px !important","font-size":"14px"},attrs:{"hide-details":"auto"},on:{"change":function($event){return _vm.erhalte_wert_von_textfeld([_vm.index, $event,item.id_station_verbrauch,'id_station_verbrauch','zaehlerstand1','tbl_station_verbrauch'])}},model:{value:(item.zaehlerstand1),callback:function ($$v) {_vm.$set(item, "zaehlerstand1", $$v)},expression:"item.zaehlerstand1"}})],1)]}},{key:"item.monatsverbrauch1",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:" schriftgroesse_normal d-none d-sm-table-cell ",staticStyle:{"padding":"1px 0px 7px 0px","width":"100px","color":"#669999"}},[_vm._v(" "+_vm._s(item.monatsverbrauch1)+" ")])]}},{key:"item.jahressverbrauch1",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:" schriftgroesse_normal d-none d-md-table-cell ",staticStyle:{"padding":"1px 0px 7px 0px","width":"100px","color":"#669999"}},[_vm._v(" "+_vm._s(item.jahressverbrauch1)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }