// src/store.js (Vue CLI 1.x & 2.x) oder src/store/index.js (Vue CLI 3.x oder neuer)

import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    token: '',
    user: {},
    id_sar_global:'',
    user_id:''
  };
};

//******************** noch ändern, da in sessionstore im Browser speichert, besser ist in httpOnly cookie !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//*************************************** https://blog.logrocket.com/jwt-authentication-best-practices/ */

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    isLoggedIn: state => {
      return state.token;
    },
    getUser: state => {
      return state.user;
    },
    get_id_sar_global : state => {
      return state.id_sar_global;
    },
    getUserId: state => {
      return state.user_id;
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_ID_SAR: (state, id_sar_global) => {
      state.id_sar_global = id_sar_global;
    },
    SET_USERID: (state, user_id) => {
      if (typeof(user_id) !== 'undefined') {
       // console.log(' 52 store.js / user_id: ' +user_id);
        state.user_id = user_id;
      }
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    login: ({ commit }, { token, user, user_id }) => {
       // login: ({ commit, dispatch }, { token, user }) => {
      commit('SET_TOKEN', token);
      commit('SET_USER', user);
      commit('SET_USERID', user_id);
      // set auth header
      Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    logout: ({ commit }) => {
      commit('RESET', '');
    }
  }
});