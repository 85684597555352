/* eslint-disable no-mixed-spaces-and-tabs */


<template>
   


<div mdi-view-dashboard>

       <h2 class="primary--text  ma-6">aktuelle Werftpunkteliste</h2>
       <!-- --  {{ testiduser }} 
 {{ user_id }}-->

        <v-row justify="left" class="ma-0 pa-0" >
            <v-col cols="6" xs="12" >
                <v-select  class="mb-0 ml-0 pa-0 black--text "  @change="update_id_sar_global();zeige_werftliste()" outlined   
                :items="schiffsliste_auswahl"
                return object 
                v-model=selected_id_sar 
                item-text="schiff_name"
                item-value= "id_sar"
                label="wähle SAR-Einheit"> 
                </v-select>
            </v-col>
       
           
        </v-row>



       <!-- <v-row justify="left" class="ma-0 pa-0" >
       
            <v-col >
                <v-card-title class="mt-n12" >
                  <v-checkbox class="ml-n3 "
                  color="#669999"
                      v-model="sarselected"
                      label="SRK"
                      value="SRK"
                      @change="zeige_werftliste()"
                  >
                  </v-checkbox>
                  <v-checkbox :class="{'ml-2': $vuetify.breakpoint.smAndDown, 'ml-5': $vuetify.breakpoint.smAndUp}"
                  color="#669999"
                      v-model="sarselected"
                      label="SRB"
                      value="SRB"
                      @change="zeige_aufgaben()"
                  >
                  </v-checkbox>
                  <v-checkbox :class="{'ml-4': $vuetify.breakpoint.smAndDown, 'ml-15': $vuetify.breakpoint.smAndUp}"
                  color="#669999"
                      v-model="statusselected"
                      label="offen"
                      value="offen"
                      @change="zeige_aufgaben()"
                  ></v-checkbox>
                 
                </v-card-title>
            </v-col>
        </v-row> -->

       <v-row justify="right" class="mt-n4 pa-0" >
            
       
            <v-col xs="10" sm="8" md="6">
                <v-card-title class="mt-n14 mb-6" >
                    <v-text-field  n5
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Filter"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
            </v-col>
            <!-- <v-col xs="2" sm="4" md="6">
                <v-checkbox :class="{'ml-3 mt-n5 mr-6 float-right': $vuetify.breakpoint.smAndDown, 'ml-0 mt-n16 mr-16 float-right': $vuetify.breakpoint.smAndUp}"
                color="#669999"
                      v-model="internselected"
                      label="nur intern"
                      value="intern"
                      @change="zeige_aufgaben()"
                  ></v-checkbox>
            </v-col> -->

        </v-row>

        <v-divider class="mb-6 hidden"></v-divider>

    <!-- https://vuetifyjs.com/en/components/data-tables/#dense -->
       
        <v-data-table 
            ref="tabelle" class="mt-n6"
            dense
            :headers="headers"
            :items="werftliste"
            :search="search"
            item-key="id_werftpunkt_sar_einheit"
            sort-by="werftzeit_bezeichnung"
            multi-sort
            group-by="werftzeit_bezeichnung"
            :class="elevation-1"
            show-group-by
            group-asc
            :items-per-page="100"
            :mobile-breakpoint="2"


            :loading="ladetabelle"
            loading-text="Lade Daten... "
            loader-height="7"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
        >
     
        <!-- <template v-slot:item.werftzeit_bezeichnung="{ item }">
          <div style="margin: 10px 0px 10px 5px !important"> 
            <span style="color:gray; "> {{ item.werftzeit_bezeichnung }} </span>
          </div>
      </template> -->

        <!-- https://www.codeply.com/p/ys4Df2OLiE/vuetify-toggle-groups    bei Gelegenheit umbauen  (ein ausklappen)-->
        
          

     
            <!--************** Ausklapp Zeile mit weiteren Infos **************-->
            <template  v-slot:expanded-item="{ headers,  item }">
              
              <td v-if = "item.einzelmodul_bezeichnung ">
                {{ item.gruppenmodul_bezeichnung }} / {{ item.einzelmodul_bezeichnung }}     
              </td>

              <td colspan="4">
                       Beschreibung: {{ item.beschreibung }}    
              </td>
             
              <td >
                       Ansprechpartner: {{ item.benutzer }}     
              </td>
                  
             </template>

<!-- 
        <template v-slot:group.header="{items, isOpen, toggle}">
                <th colspan="4" style="background-color:gray; color:white; font-size:15px; font-weight:normal;">
                  <v-icon @click="toggle"
                    >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                  </v-icon>
                  {{ items[0].schiff_name }}
                </th>
          </template> -->

<!--            
       <template v-slot:item.gruppen_modul_bezeichnung="{ item }">
          <div style="margin: 10px 0px 10px 5px !important; "> 
            <div style="color:gray; margin: 0px 10px 7px 0px; font-style:italic; font-weight: normal; width:80%; float:left;"> #{{ item.gruppen_modul_bezeichnung }} </div>
            <div style="font-weight:bold; float:left;width:70px; text-align:right;   margin:0px -50px 0px 0px;"> {{ item.aufgabe_status }} </div>
            <br>
            <div style="color:gray; float:left !important; width:100%;margin: -5px 10px 0px 0px; font-weight:bold; "> {{ item.arbeitstitel }}
              <span v-if="item.aendat"  style="font-weight:normal; font-size:11px;width:auto; margin-left: 10px; margin-top: 0px !important;"> ({{formatDate(item.aendat.substr(0, 10))}})</span>
            </div>
            <br>
            <div style="color:gray; width:100%;margin:-20px 0px 20px 0px !important; "> {{ item.beschreibung }} </div>
          </div>
      </template>  -->

<!-- 

      <template v-slot:item.id_aufgabe="{ item }">
          <div style="margin: 10px 0px 10px 5px !important"> 
            <span style="color:gray; "> {{ item.benutzer }} </span>
          </div>
      </template>


      <template v-slot:item.id_aufgabe="{ item }">
          <Popup_aufgabe  @emit_closepopup ="popupgeschlossen"  title="Aufgaben  / ToDo anzeigen"  :anzahl_aufgaben_dateien=item.anzahl_dateien   :current_user_id=current_user_id  herkunft="von_aufgabenliste" :prop_id_aufgabe =item.id_aufgabe  :id_sar =item.id_sar  :id_typ_sar =item.id_typ_sar  :schiff_name =item.schiff_name  :anzahl_aufgaben=item.anzahl_aufgaben  :id_gmv =item.id_gmv :id_emv =0  :gruppen_modul_bezeichnung =item.Gruppenmodul_Bezeichnung  :einzel_modul_bezeichnung =0  :id_zuordnung_final_gmv_sar = item.id_zuordnung_final_gmv_sar      :id_zuordnung_final_emv_sar =0  class="ma-3"></Popup_aufgabe> 
      </template>
 -->

         

        </v-data-table>






</div>
</template>
<script>

import WerftlisteService from '@/services/WerftlisteService.js';
// import Popup from '../components/Popup.vue';
import SchiffeService from '../services/SchiffeService';
import AuthService from '@/services/AuthService.js';


import { EventBus } from '../main';
// import Popup_aufgabe from '../components/Popup_aufgabe.vue';

export default {
  data() {
            return {

               // user_id: localStorage.getItem("user_id"),   //  holt sich user id aus localstorage
                
                sarselected: ['SRK'],
                statusselected: ['offen', 'in Arbeit'],
                internselected: '0',
                schiffsliste:"",
                schiffsliste_auswahl:"",
                selected_id_sar: localStorage.id_sar_ausgewaehlt_global,
                selected_name_sar : localStorage.name_sar_ausgewaehlt_global,
                in_liste_schiff_alt:'',
                werftliste: [],
                search: '',
                
                current_user_id:'',

                headers: [
                // {
                //     text: 'Schiff',
                //     align: 'start',  //********  d-none d-lg-table-cell   = wird nur bei größeren Viewports (lg = large) angezeigt :   https://stackoverflow.com/questions/59871140/vuejs-how-to-hide-some-data-table-columns-on-mobile-size */
                //     value: 'schiff_name',
                //     groupable: true,
                // },


                {
                    text: 'Werftzeit Bezeichnung',
                    align: 'start',  //********  d-none d-lg-table-cell   = wird nur bei größeren Viewports (lg = large) angezeigt :   https://stackoverflow.com/questions/59871140/vuejs-how-to-hide-some-data-table-columns-on-mobile-size */
                    value: 'werftzeit_bezeichnung' ,
                    groupable: true,
                },

                //***** !!!!!!!!!!!!!!  ***************  wenn bei align  folgendes steht :  d-none  ,  dann wird dieser Spaltenkopf ausgeblendet. Wird aber für die FILTERNG eingebunden!!!!!!!!!!!! */
               // { text: 'Modul', value: 'gruppen_modul_typ_bezeichnung', align: 'start   d-none', width: '0px', groupable: true },
                // { text: 'Arbeitstitel', value: 'arbeitstitel', align: 'left    d-none', width: '20%', groupable: false },  //********  d-none d-md-table-cell   = wird nur bei größeren Viewports (md=Medium)angezeigt :
                
                { text: '', value: 'data-table-expand' , align: 'start hidden-xs-and-down   hidden-md-and-up ', sortable: false, groupable: false},  //********  verstecken ab mittleren Viewports und höher :  hidden-md-and-up

                // { text: 'Bereich', value: 'gruppen_modul_typ_bezeichnung', align: 'start', groupable: true },


               { text: 'GM', value: 'gruppen_modul_bezeichnung', sortable: true,  class:'css_status',  groupable: false  },
               { value: 'gruppenmodul_bezeichnung', sortable: true,  class:'css_status', align: 'start hidden-md-and-down  ', groupable: false  },


               { text: 'EM', value: 'einzelmodul_bezeichnung', sortable: true, align: 'start hidden-md-and-down  ', class:'css_status',  groupable: false  },
               
                { text: 'Titel', value: 'arbeitstitel', align: 'start', groupable: false },


               { text: 'Beschreibung', value: 'beschreibung', sortable: true, align: 'start hidden-md-and-down  ', groupable: false },
               
                 { text: 'Ansprechpartner', value: 'benutzer', align: 'right  start d-none d-md-table-cell' , groupable: false},  //********  d-none d-md-table-cell   = wird nur bei größeren Viewports (md=Medium)angezeigt :
               
               
               //  { text: '', value: 'data-table-expand' , align: 'right start hidden-xs-and-down   hidden-md-and-up ', sortable: false, groupable: false},  //********  verstecken ab mittleren Viewports und höher :  hidden-md-and-up
               
              //  { text: 'Aktion', value: 'id_aufgabe', sortable: true, width: '5%', groupable: false  },


                ],
                
               expanded: [],
               singleExpand: true, //**********  für Tabellenzeie Info ausklappen (immer nur eine oder beliebig viele)) */
                
               
            };
    },
    computed: {
    // 
    //         testiduser () {
    //           return this.$store.state.user + this.$store.state.user_id
    //       }
    },
    methods: {

      async zeige_sar_einheit_auswahl() {
            
            try {

                //var id_user =  localStorage.getItem("user_id");  //  user_id aus dem localstorage
                var id_user = this.$store.state.user_id;   //  user_id aus dem VUEX-Store


                const credentials = {
                    id_user: id_user
                };

                const response = await SchiffeService.zeigeschiffe(credentials);
                if (typeof response.msg !=='undefined'){this.$router.push("/login")} //****  wenn keine Anmeldung */
                this.schiffsliste_auswahl = response.recordset;
            }
            catch (error) {
            // this.msg = error.response.data.aufgabenliste;
            }
        },




      async hole_user_id() {
                try {
                    const credentials = {
                    username: this.username,
                    password: this.password
                    };
                    const response = await AuthService.hole_user_id(credentials);
                    
                    
                    this.current_user_id = response;
                   
                }
                catch(err){
                    console.log('token is null: ',err);
                }
        },






      popupgeschlossen(){
           
            this.zeige_werftliste();
        },




        formatDate (date) {
          if (!date) return null

          var arr= date.split('-'),
                  year = arr[0],
                  month =  arr[1],
                  day=  arr[2];
          return `${day}.${month}.${year}`
        },

        popupzeigen() {
          this.$modal.show('example')
        },

        erhalte_wert_von_textfeld(index) {
      
          this.update_feld(index)

        },

//         
//         async  update_feld(index) {
//             try {
// 
//                 const credentials = {
//                     wert: index[1],
//                     id: index[2],
//                     id_bezeichnung: index[3],
//                     feld: index[4],
//                     tabelle: index[5]
//                 };
//                 
//                 const response = await AufgabenService.update_text_feld(credentials);
//                 this.zeige_aufgaben() 
// 
//                 if (response.msg) { //**********  keine gültige Session */
//                     this.msg = response.msg;
//                 }
//             }
//             catch (error) {
//                 this.msg = "Fehler" //error.response.data.schiffsliste;
//             }
//         },


        async update_id_sar_global() {
          //*** wenn Sar-Einheit in Dropdown ausgewählt wurde, dann diesen Wert in localStorage speichern //******  globale session variabel setzen  (siehe in App.vue  / mounted und watch ) */

          if(typeof(this.selected_id_sar) !== "undefined" && this.selected_id_sar !=='' ) { 
                localStorage.id_sar_ausgewaehlt_global = this.selected_id_sar  
                EventBus.$emit('sende_globale_id_sar',this.selected_id_sar)
            } 
        }
,


        async zeige_werftliste() {
            try {
             

              this.ladetabelle = true

              //var id_user =  localStorage.getItem("user_id");  //  user_id aus dem localstorage
             // var id_user = this.$store.state.user_id;   //  user_id aus dem VUEX-Store
           //  if(typeof(this.selected_id_sar) !== "undefined" && this.selected_id_sar !=='' ) { localStorage.id_sar_ausgewaehlt_global = this.selected_id_sar  } 

              
              const credentials = {
                 // id_user: id_user,  ***************************  die id_user   wird aus dem json Token auf der API-Seite entnommen  (somit bei Übergabe nicht sichtbar, nur das verschlüsselte Token))
                  //sarselected:this.sarselected,
                  id_sar:  localStorage.id_sar_ausgewaehlt_global
                  // statusselected:this.statusselected,
                  // internselected:this.internselected,

              };

             
                // ************  rufe Daten auf, Datenstrom dann in variabel  aufgabenliste *******
                const response = await WerftlisteService.zeigewerftliste_per_id(credentials);
              
                if (typeof response.msg !=='undefined'){this.$router.push("/login")} //****  wenn keine Anmeldung */

                this.werftliste = response.recordset ;
                
                this.ladetabelle = false;

                this.selected_name_sar = this.werftliste[1].schiff_name
                this.selected_id_sar = this.werftliste[1].id_sar


                this.userFilterKey= "offen";
            }
            catch (error) {
               // this.msg = error.response.data.aufgabenliste;
            }
        },
        zum_login() {
            this.$router.push("/login");
        }


    },

    //******************************* wichtig für update Select-Feld auf anderen Seiten  (wenn SAR-Einheit wechselt) , dann wird dies überall übernommen***** Michael    */
    watch: {
        selected_id_sar: function (neuer_wert) {
            this.zeige_sar_einheit_auswahl();
            this.zeige_gruppenmodule(neuer_wert) ;
            }
    },



    created() {

          //************* wenn auf Seite Schiffsliste ein (anderes) Schiff ausgewählt wird ) ***** Michael  */
          EventBus.$on('sende_globale_id_sar', eventbusparameter => {
          this.eventbusmsg =eventbusparameter;
          console.log('Custom event triggered  - empfangen von Werftliste - zeile 450!!!' + this.eventbusmsg);
          localStorage.id_sar_ausgewaehlt_global = this.eventbusmsg;
          this.selected_id_sar=this.eventbusmsg;
          this.zeige_werftliste() ;
          
        })

      if (!this.$store.getters.isLoggedIn) {
                this.$router.push('/login');
      }

      this.zeige_werftliste() ;
      this.zeige_sar_einheit_auswahl();

            if(typeof(localStorage.name_sar_ausgewaehlt_global) !== "undefined" && localStorage.name_sar_ausgewaehlt_global !=='' ) {
                this.selected_name_sar = localStorage.name_sar_ausgewaehlt_global
            }

            if(typeof(localStorage.id_sar_ausgewaehlt_global) !== "undefined" && localStorage.id_sar_ausgewaehlt_global !=='' ) {
                this.id_sar_ausgewaehlt_global = localStorage.id_sar_ausgewaehlt_global
            }


      this.hole_user_id();

        if (!this.$store.getters.isLoggedIn) {
          this.$router.push('/login');
        }

       // this.zeige_aufgaben().this.einklappen();
        
        // EventBus.$on('testeventbus', eventbusparameter => {
        //   this.eventbusmsg =eventbusparameter
        // })
      
    },

    mounted () {

      let table = this.$refs.tabelle;
            let keys = Object.keys(table.$vnode.componentInstance.openCache);
            keys.forEach(x => {
              table.$vnode.componentInstance.openCache[x] = false;
            })
    },


    components: { 
      // Popup ,
     // Popup_aufgabe
    }
};



</script>

<style>

.eigene_textarea {

font-size:12px; padding:2px;


}

.css_status {
color:green;
font-size:12px; padding:2px;


}

.schatten{
  opacity:0.7;
	background: #F8F8F8; 
	border: solid #BDBDBD 1px; 
	box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
	-webkit-box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
	-moz-box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
}



.right {
 position:relative;
  float:right;
  margin-right:10%;
}
</style>