<template>

  <div class="text-center">


    <v-dialog @keydown.esc="dialog = false"  max-width="700" class="ma-0 pa-0 green">

          <template v-slot:activator="{on}">
            <v-btn icon  x-small v-on="on" @click="zeige_infos"><v-icon class="teal--text ml-2" >mdi-information-variant</v-icon><span class="teal--text"> ({{anzahl_infos}})</span> </v-btn>
          </template>

         <!-- <v-form ref="form"  @submit.prevent="onUpdate" class="white overflow-hidden"> -->
          

            <v-card >
                <v-card-title class=" grey lighten-2  body-1 "> {{gruppen_modul_bezeichnung}}  
                  <!-- ({{anzahl_infos}} Infos)   -->
                  <v-icon class="ml-12" color="green darken-2"  @click="neue_info_einfuegen(id_sar, id_typ_sar, id_gmv, id_emv, id_zuordnung_final_gmv_sar, id_zuordnung_final_emv_sar)">mdi-pencil-plus</v-icon> </v-card-title>
            </v-card>

            
             <v-card class="text-sm-body-2" elevation="2" v-for=" item in infoliste" :key="item">

        <!-- {{item.id_user}} {{current_user_id}}  -->
                                        
                              <!--###############################################################   bei checkboxen muss es  input-value anstelle von value heißen !!!!!!!      -->
                              <v-checkbox class=" mt-5    mr-2 float-right"
                                    color="#669999"
                                          v-model="item.oeffentlich"
                                          label="öffentlich"  
                                          :disabled="item.id_user !== current_user_id"
                                          :value="true"
                                        input-value="value"
                                          @change="update_checkbox(item.id_info,item.oeffentlich)"
                                ></v-checkbox>


               
                        <v-text-field
                          label="Titel" 
                          v-model="item.infotitel"
                          @change= "update_feld([index, $event,item.id_info,'id_info','infotitel','tbl_infos'])"
                          hide-details="auto"
                          class="pa-5  css-text-rot"
                          color="primary"
                          
                        ></v-text-field>
                        


                        <v-textarea
                          rows="3"
                          row-height="20"
                          solo
                          
                        
                          label="Beschreibung"
                          v-model="item.beschreibung"
                          @change= "update_feld([index, $event,item.id_info,'id_info','beschreibung','tbl_infos'])"
                          hide-details="auto"
                          class="pa-5"
                          dense
                        ></v-textarea>


                      <!-- *************  Datum  -->
                      <div  style="position:relative; padding:10px; margin: -10px 0px 20px 20px !important; color:gray; font-size:11px;" v-if="item.aendat" >Datum: {{formatDatum(item.aendat.substr(0, 10))}} von: {{item.benutzer}}</div>

                      <v-btn type="button"  style="position:relative;float:left;  margin:20px 10px 10px 10px ;  padding:10px 5px 10px 5px;"
                                      depressed
                                      elevation="2"
                                      pa-2
                                      outlined
                                      small
                                      @click="dateilisteupload">
                                      <span>Datei hochladen</span>
                                </v-btn>
                 
                      <DateienInfos :id_info =item.id_info  :id_sar =id_sar :anzahl_dateien=item.anzahl_dateien_infos :dateipfad= dateipfad  style="margin-top:0px;"></DateienInfos> 

                 <!-- {{ id_zuordnung_final_gmv_sar }}   {{ id_zuordnung_final_emv_sar }} -->

            </v-card>

          <!-- </v-form> -->
        </v-dialog>
  </div>
</template>

<script>

import InfoService from '@/services/InfoService.js';
import axios from 'axios';

import AufgabenService from '@/services/AufgabenService.js';

import DateienInfos from './DateienInfos.vue';

import serveradresse_settings from '@/settings/serveradresse_settings.js';

// import VueJwtDecode from 'vue-jwt-decode'

export default ({

data() {
        return {
            infoliste: "",
            msg:"",
            infotitel:"",
            beschreibung:"",
            dateipfad:'',
           
            id_info:"",
           // aufgabe_status_liste: ['erledigt', 'offen', 'in Arbeit', 'Fehler'],
           
            date: (new Date(Date.now() )).toISOString().substr(0, 10),            menu: false,
           
            zeige_kalender:false,
            dateFormatted: '',
            images: null,
            imgSrc: '',
            user_id :  localStorage.getItem("user_id"),
            oeffentlich: false,
            current_user:'',
            aendat:'',

            url_foto_anzeigen: serveradresse_settings.config_server_adresse.url_foto_anzeigen
           
        }
    },

 methods: {


      //'***************************************'  Öffnet neues Fenster, um Dateien uploaden zu können (Upload-Mobil / aspx-Datei)
      dateilisteupload() {  

        if (this.id_zuordnung_final_gmv_sar !='' && this.id_zuordnung_final_emv_sar =='0') {  //******  Infos für gruppenmodule  */
            window.open(""+this.url_foto_anzeigen+"/Upload_mobil.aspx?id_emv=0&id_gmv="+this.id_gmv+"&id_sar="+this.id_sar+"&id_typ_sar=0&id_zuordnung_final_emv_sar=0&id_zuordnung_final_gmv_sar=0&id_aufgabe=0&id_lebenslauf=0&id_werftpunkt=0&id_werftpunkt_sar_einheit=0&id_info="
                +this.id_info+"&id_checkpunkt=0&ordner1=Gruppenmodule&ordner2="
                +this.id_gmv+"&ordner3=Infos&folder=Info-ID-"+this.id_info+"", 
            "_blank", "directories=0,titlebar=0,toolbar=0,scrollbars=0,resizable=1,toolbar=0,location=0, top=100,left=200,width=510,height=auto")
        }
        if (this.id_zuordnung_final_emv_sar !='') {  //******  Infos für Einzelmodule  */
            window.open(""+this.url_foto_anzeigen+"/Upload_mobil.aspx?id_emv="+this.id_emv+"&id_gmv=0&id_sar="+this.id_sar+"&id_typ_sar=0&id_zuordnung_final_emv_sar=0&id_zuordnung_final_gmv_sar=0&id_aufgabe=0&id_lebenslauf=0&id_werftpunkt=0&id_werftpunkt_sar_einheit=0&id_info="
                +this.id_info+"&id_checkpunkt=0&ordner1=Einzelmodule&ordner2="
                +this.id_emv+"&ordner3=Infos&folder=Info-ID-"+this.id_info+"", 
            "_blank", "directories=0,titlebar=0,toolbar=0,scrollbars=0,resizable=1,toolbar=0,location=0, top=100,left=200,width=510,height=auto")
        }


        },



        formatDatum (date) {
          if (!date) return null

          var arr= date.split('-'),
                  year = arr[0],
                  month =  arr[1],
                  day=  arr[2];
          return `${day}.${month}.${year}`
        },

  async  update_checkbox(id_datensatz,value) {
            try {
                const credentials = {
                    wert: value,
                    id: id_datensatz,
                    id_bezeichnung: 'id_info',
                    feld: 'oeffentlich',
                    tabelle: 'tbl_infos'
                };

                const response = await AufgabenService.update_checkbox_feld(credentials);
                
              //'***************** schickt emit an Parent, damit Liste Aufgaben neu geladen wird (mit neuer Anzahl Aufgaben))
                if(this.herkunft == "von_aufgabenliste") {this.$emit('emit_closepopup', 'von_aufgabe_aufgabenliste');}
//                   this.zeige_gruppenmodule(localStorage.id_sar_ausgewaehlt_global);
// 
                if (response.msg) { //**********  keine gültige Session */
                    this.msg = response.msg;
                }
            }
            catch (error) {
                this.msg = "Fehler" //error.response.data.schiffsliste;
            }
        },




//       onFile(e) {
//       const files = e.target.files
//       if (!files.length) return
// 
//       const reader = new FileReader()
//       reader.readAsDataURL(files[0])
//       reader.onload = () => (this.imgSrc = reader.result)
//     },




            //'***************** schickt emit an Parent, damit Liste GM neu geladen wird (mit neuer Anzahl Infos))
            closePopup() {
                  this.$emit('emit_closepopup', 'von_info');
                },





      uploadFile() {
        this.Images = this.$refs.file.files[0];
      },
      submitFile() {
        const formData = new FormData();
        formData.append('file', this.Images);
        const headers = { 'Content-Type': 'multipart/form-data' };
        axios.post('https://httpbin.org/post', formData, { headers }).then((res) => {
          res.data.files; // binary representation of the file
          res.status; // HTTP status
        });
      },



      async neue_info_einfuegen(id_sar, id_typ_sar, id_gmv, id_emv, id_zuordnung_final_gmv_sar, id_zuordnung_final_emv_sar) {
        try {
          const credentials = {
                   
                    id_sar: id_sar,
                    id_typ_sar:id_typ_sar,
                    id_gmv: id_gmv,
                    id_emv: id_emv,
                    id_zuordnung_final_gmv_sar: id_zuordnung_final_gmv_sar,
                    id_zuordnung_final_emv_sar: id_zuordnung_final_emv_sar,
                    user_id: this.user_id
                };
               // alert(credentials.id_sar)
                const response = await InfoService.neue_info_einfuegen(credentials);
                this.zeige_infos() ;

                this.closePopup();

                if (response.msg) { //**********  keine gültige Session */
                    this.msg = response.msg;
                }
           }
            catch (error) {
                this.msg = "Fehler" //error.response.data.schiffsliste;
          }
      },

       

        async  update_feld(index) {
            try {

                const credentials = {
                    wert: index[1],
                    id: index[2],
                    id_bezeichnung: index[3],
                    feld: index[4],
                    tabelle: index[5]
                };
                
                const response = await InfoService.update_text_feld(credentials);
                this.zeige_infos() 

                if (response.msg) { //**********  keine gültige Session */
                    this.msg = response.msg;
                }
            }
            catch (error) {
                this.msg = "Fehler" //error.response.data.schiffsliste;
            }
        },



        async zeige_infos() {
            try {
               const credentials = {
                    id_sar: this.id_sar,
                    id_zuordnung_final_emv_sar: this.id_zuordnung_final_emv_sar,
                    id_zuordnung_final_gmv_sar: this.id_zuordnung_final_gmv_sar,
                    id_gmv: this.id_gmv,
                    id_emv: this.id_emv
                };

              
                if (this.id_zuordnung_final_emv_sar !='') {  //******  Infos für Einzelmodule  */
                  const response = await InfoService.zeigeinfo_per_id_zuordnung_final_emv_sar(credentials);
                  this.infoliste = response.recordset;
                  this.idsar= response.recordset
                  this.schiff_name= response.recordset[0].schiff_name
                  this.id_info = response.recordset[0].id_info
                  this.oeffentlich = response.recordset[0].oeffentlich
                  this.aendat = response.recordset[0].aendat

                  this.dateipfad ="Einzelmodule/"+this.id_emv+"/Infos/Info-ID-"+this.id_info  // für den Link zu den Datei-Infos (Einzelmodule)
               
               }
                else //******  Infos für Gruppenmodule  */
                 {
                  const response = await InfoService.zeigeinfo_per_id_zuordnung_final_gmv_sar(credentials);
                  this.infoliste = response.recordset;
                  this.idsar= response.recordset
                  this.schiff_name= response.recordset[0].schiff_name
                  this.id_info = response.recordset[0].id_info
                  this.oeffentlich = response.recordset[0].oeffentlich
                  this.aendat = response.recordset[0].aendat

                  this.dateipfad ="Gruppenmodule/"+this.id_gmv+"/Infos//Info-ID-"+this.id_info  // für den Link zu den Datei-Infos (Gruppenmodule)
                
                 }
              
            }
            catch (error) {
                this.msg = "" //error.response.data.schiffsliste;
            }
        },

        // onUpdate() {
        //   const credentials = {
        //             id_sar: this.id_sar,
        //             id_zuordnung_final_gmv_sar: this.id_zuordnung_final_gmv_sar,
        //             id_zuordnung_final_emv_sar: this.id_zuordnung_final_emv_sar,
        //         };
        //   const response =  InfoService.update_info(credentials);
        //         if (response.msg) { //**********  keine gültige Session */
        //             this.msg = response.msg;
        //         }
        // },

        formatDate (date) {
          if (!date) return null

          var arr= date.split('-'),
                  year = arr[0],
                  month =  arr[1],
                  day=  arr[2];
          return `${day}.${month}.${year}`
        },
    },

    props: {
          id_sar: { type: Number, required: true },
          id_typ_sar: { type: Number, required: true },
          id_zuordnung_final_gmv_sar : { type: Number, required: false },
          id_zuordnung_final_emv_sar : { type: Number, required: false },
          gruppen_modul_bezeichnung:{type: String, required: false},
          einzel_modul_bezeichnung:{type: String, required: false},
          schiff_name:{type: String, required: false},
          anzahl_infos: { type: String, required: false },
          id_gmv: { type: Number, required: false },
          id_emv: { type: Number, required: false },
          current_user_id: { type: Number, required: false },

          
    }, 

    created() {
     // this.zeige_infos() 
      this.hole_user_id()
    },

    components: {
        //  UploadImage,
      // Camera_neu,
      DateienInfos
}

})
</script>


<style>

.css-text-rot .v-text-field__slot input {
  color: #b30505 !important;
}

.schatten{
  opacity:0.7;
	background: #F8F8F8; 
	border: solid #BDBDBD 1px; 
	box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
	-webkit-box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
	-moz-box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
}

.css_datei_upload{

  width:60%;
}

.calenderfeld{
      width: 177px;
     
      font-size: 0px;
      text-decoration: none;
}

.css_datumsanzeige {
position:relative;
  font-size: 15px;
  top:50px;
  left:35px;
}


.select {
      width: 60px;
      max-height: 60px;
      font-size: 11px;
    }

.right {
 
  float:right;
  margin-right:10%;
}
</style>