



//*************  Beispiel für Socket.io:    https://deepinder.me/creating-a-real-time-chat-app-with-vue-socket-io-and-nodejs */


//*****************************  socket param auch anpassen !!!!!!!!!!  (Datei: socket_param.env)    ) */



module.exports.config_server_adresse = {

    
// url : 'http://localhost:3100/api/',   //  ***** API-Anwendung
// url_foto_upload:'http://localhost:1300/upload',  //  ***** Hauptanwendung
// url_foto_anzeigen:'http://localhost:1300/uploader'  //  ***** Hauptanwendung





    url : 'https://api.technik-seenotretter.de/api/',
   // //// url_foto_upload:'https://technik-seenotretter.de/upload',  //  ***** ALT  ******  Hauptanwendung Fotos hochladen
    url_foto_upload:'https://test.technik-seenotretter.de/upload',  //  ***** Hauptanwendung Fotos hochladen
    url_foto_anzeigen:'https://technik-seenotretter.de/uploader'  //  ***** Hauptanwendung Fotos anzeigen



};